<template>
  <b-container class="signin mt-4">
    <b-row>
      <b-col class="text-center">
        <h1 class="heading">Signin for AB Pools LLC.</h1>
      </b-col>
    </b-row>
    
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-1"
        label="Username:"
        label-for="username"
      >
        <b-form-input
          id="username"
          v-model="form.username"
          type="email"
          required
          placeholder="Enter Username..."
        ></b-form-input>
      </b-form-group>

      <b-form-group 
        id="input-group-2" 
        label="Password:" 
        label-for="password"
      >
        <b-form-input
          id="password"
          v-model="form.password"
          type="password"
          required
          placeholder="Enter Password..."
          autocomplete="on"
        ></b-form-input>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit</b-button>
    </b-form>
  </b-container>
</template>

<script>
import { HTTP } from "../http-common";

export default {
  name: "Signin",

  components: {
  },

  data() {
    return {
      form: {
        username: "",
        password: "",
      }
    };
  },

  methods: {
    onSubmit: async function(evt) {
      if( this.form.username == "" || this.form.password == "") {
        alert("One of the fields is incomplete.");
        return;
      }
      evt.preventDefault()
      await HTTP.post("/login", this.form).then(res => {
        const response = res.data;

        if(response.data.length > 0 && this.$cookies.isKey("ab_pools")) {
          this.$store.commit('setSession');
          this.$router.push('/');
        } else if(response.error) {
          alert("There was an error logging in!");
        } else {
          alert(response.messages);
        }
      });
    }    
  }
};
</script>

<style scoped lang="scss">
.heading {
  font-size: $base-font-size * 1.4;
  font-family: "Roboto", sans-serif;
  display: inline;
}
</style>
